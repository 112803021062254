import { call, all, put, takeLatest } from "redux-saga/effects";
import * as actionTypes from "../constants";

import apiClient from "../apiClient";
import { getAccessToken } from "../../utils/jwt";

import { AxiosResponse } from "axios";
import { ServerResponse } from "node:http";

async function shooterGetAnswer(
  data: string
): Promise<AxiosResponse<any, any>> {
  return apiClient.post("/chat/message", data, {
    headers: { Authorization: getAccessToken() },
  });
}
async function shooterGetChatAnswer(
  data: object
): Promise<AxiosResponse<any, any>> {
  return apiClient.post("/chat/message", data, {
    headers: { Authorization: getAccessToken() },
  });
}

function* getAnswer(): any {
  return yield takeLatest(
    actionTypes.GET_ANSWER_REQUEST,
    function* (action: any) {
      try {
        yield put({
          type: actionTypes.ADD_MESSAGE,
          payload: action.payload,
        });
        const data: Promise<AxiosResponse<any, any>> = yield call(
          shooterGetChatAnswer,
          action.payload
        );
        yield put({
          type: actionTypes.ADD_CHAT_MESSAGE,
          payload: {
            ...data,
          },
        });
      } catch (error) {
        yield put({
          type: actionTypes.GET_ANSWER_REQUEST_ERROR,
        });
        console.log(error);
      }
    }
  );
}
function* getChatAnswer(): any {
  return yield takeLatest(
    actionTypes.GET_CHAT_ANSWER_REQUEST,
    function* (action: any) {
      try {
        yield put({
          type: actionTypes.ADD_MESSAGE,
          payload: action.payload.data.messages[0].content,
        });
        yield put({
          type: actionTypes.ADD_CHAT_MESSAGE,
          payload: action.payload.data.messages[1].content,
        });
        // yield put({
        // 	type: actionTypes.GET_ANSWER_REQUEST_SUCCESS,
        // 	payload: {
        // 		...action.payload,
        // 	},
        // });
      } catch (error) {
        yield put({
          type: actionTypes.GET_ANSWER_REQUEST_ERROR,
        });
        console.log(error);
      }
    }
  );
}

function* getAnswerAgain(): any {
  return yield takeLatest(
    actionTypes.GET_ANSWER_AGAIN_REQUEST,
    function* (action: any) {
      try {
        const data: Promise<AxiosResponse<any, any>> = yield call(
          shooterGetAnswer,
          action.payload
        );
        yield put({
          type: actionTypes.GET_ANSWER_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error) {
        yield put({
          type: actionTypes.GET_ANSWER_REQUEST_ERROR,
        });
        console.log(error);
      }
    }
  );
}

async function shooterGetChatHistory(
  chatId: string
): Promise<AxiosResponse<any, any>> {
  return apiClient.get(`/chat/message/${chatId}`, {
    headers: { Authorization: getAccessToken() },
  });
}

function* getChatHistory(): any {
  return yield takeLatest(
    actionTypes.GET_CHAT_HISTORY_REQUEST,
    function* (action: any) {
      try {
        const data: Promise<AxiosResponse<any, any>> = yield call(
          shooterGetChatHistory,
          action.payload
        );
        yield put({
          type: actionTypes.GET_CHAT_HISTORY_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error) {
        console.log(error);
      }
    }
  );
}

async function shooterDeleteChatHistory(): Promise<AxiosResponse<any, any>> {
  return apiClient.delete(`/chat/message/all`, {
    headers: { Authorization: getAccessToken() },
  });
}

function* deleteChatHistory(): any {
  return yield takeLatest(
    actionTypes.DELETE_CHAT_HISTORY_REQUEST,
    function* (action: any) {
      try {
        const data: Promise<AxiosResponse<any, any>> = yield call(
          shooterDeleteChatHistory
        );
        yield put({
          type: actionTypes.DELETE_CHAT_HISTORY_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
        yield put({
          type: actionTypes.GET_CHAT_HISTORY_REQUEST,
          payload: "last",
        });
      } catch (error) {
        console.log(error);
      }
    }
  );
}
async function shooterEmbedding(): Promise<AxiosResponse<any, any>> {
  return apiClient.post(
    `/user/genEmbedding`,
    {},
    {
      headers: { Authorization: getAccessToken() },
    }
  );
}

function* Embedding(): any {
  return yield takeLatest(
    actionTypes.EMBEDDING_HISTORY_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(shooterEmbedding);
        yield put({
          type: actionTypes.EMBEDDING_HISTORY_REQUEST_SUCCESS,
          payload: {
            ...data,
          },
        });
      } catch (error: any) {
        console.log(error);
        action.helpers.setStatus({ success: false });
        action.helpers.setErrors({
          submit: error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
        });
        action.helpers.setSubmitting(false);
      }
    }
  );
}

function shooterGetMycQuickResponses() {
  return apiClient.get("/user/quick-replies/magic-myc", {
    headers: { Authorization: getAccessToken() },
  });
}

function* getMycQuickResponses(): any {
  return yield takeLatest(
    actionTypes.GET_MYC_QUICK_RESPONSES_REQUEST,
    function* (action: any) {
      try {
        const data: ServerResponse = yield call(shooterGetMycQuickResponses);
        action.callBack({ status: "success", returnData: data });
      } catch (error) {
        console.log(error);
        action.callBack("error");
      }
    }
  );
}
export default function* runBlockingCallsExample() {
  yield all([
    getAnswer(),
    getChatHistory(),
    deleteChatHistory(),
    getChatAnswer(),
    getAnswerAgain(),
    Embedding(),
    getMycQuickResponses(),
  ]);
}

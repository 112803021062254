import * as actionTypes from "../constants";

const initialState: {
  dataFlow: string;
  model: any;
} = {
  dataFlow: "main",
  model: null,
};

const reducer = (
  prevState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    // ** When user press enterKey or send message button
    case actionTypes.CHANGE_DATA_FLOW:
      return {
        ...prevState,
        dataFlow: action.payload,
      };
    case actionTypes.MODEL_SELECTION:
      console.log(action.payload);
      return {
        ...prevState,
        model: action.payload,
      };
    default:
      return prevState;
  }
};

export default reducer;

import {
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FaCheck } from "react-icons/fa";
import { IoIosArrowDown } from "react-icons/io";
import { useMutation } from "react-query";
import http from "../../services/http-common";
import { setSessionExpired, useMainController } from "../../context";
import { toast } from "react-toastify";
import { Chip } from "@mui/material";
import { updateModelSelection } from "../../store/dataFlow/actions";

const SelectModel = () => {
  const dispatch = useDispatch();
  const dispatchHttp = useMainController();
  const { model } = useSelector((state) => state.dataFlow);
  const [allModel, setAllModel] = useState(null);

  const handleChange = (text, type, status) => {
    dispatch(updateModelSelection({ name: text, type: type, status: status }));
  };

  const getAllMModels = useMutation(
    () => {
      return http.post(
        `/llms/getall`,
        {
          isEnabled: true,
        },
        null,
        setSessionExpired,
        dispatchHttp
      );
    },
    {
      onSuccess: ({ data }) => {
        setAllModel(data?.records);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
      onMutate: () => {},
      onSettled: () => {},
    }
  );
  useEffect(() => {
    getAllMModels.mutate();
    if (!model) {
      dispatch(
        updateModelSelection({
          name: "GPT-4o",
          type: "gpt",
          status: "public",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Menu
        placement="bottom-end"
        dismiss={{
          itemPress: true,
        }}
      >
        <MenuHandler>
          <div className="flex justify-start items-center gap-2 ">
            <Chip
              variant="outlined"
              size="sm"
              label={model ? model?.name : ""}
              className="w-[min-content] normal-case !bg-[#000080] !text-white rounded-full "
            />
            <IoIosArrowDown fontSize="small" />
          </div>
        </MenuHandler>

        {model && model?.status === "private" ? (
          <span className="rounded-md  !text-xs bg-[orange] outline-[orange] !text-[white] px-1 font-semibold">
            Private
          </span>
        ) : (
          <span className="rounded-md  !text-xs outline-[green] bg-[green] !text-[white] px-1 font-semibold">
            Public
          </span>
        )}
        <MenuList>
          {allModel?.map((itm) => {
            return (
              <MenuItem
                className={`${model.name === itm.name && "bg-[#d6e5f3]"}`}
                key={model.name}
                onClick={() => {
                  handleChange(itm.name, itm.type, itm.status);
                }}
              >
                <span className="flex justify-between items-center gap-2 cursor-pointer ">
                  {itm.name}{" "}
                  {itm.status === "private" && (
                    // <Chip
                    //   label="Private"
                    //   color="success"
                    //   size="small"
                    //   variant="outlined"
                    // />
                    <span className="rounded-md outline !text-xs outline-[#000080] !text-[#000080] px-1">
                      Private
                    </span>
                    // <Chip
                    //   size="sm"
                    //   color="green"
                    //   variant="outlined"
                    //   value="Private"
                    // />
                  )}
                  {model?.name === itm.name && <FaCheck />}
                </span>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};

export default SelectModel;

import * as actionTypes from "../constants";

interface UserState {
  isAuthenticated: boolean;
  user: object;
}

const initialState: UserState = {
  isAuthenticated: false,
  user: {},
};

const reducer = (
  prevState = initialState,
  action: { type: string; payload: any }
) => {
  switch (action.type) {
    case actionTypes.POST_SIGNIN_REQUEST_SUCCESS:
      localStorage.setItem("accessToken", action.payload?.data.access_token);
      localStorage.setItem("botUniqueUserId", Date.now().toString());
      return {
        ...prevState,
        isAuthenticated: true,
        user: { ...action.payload?.data.user },
      };
    case actionTypes.POST_GOOGLE_SIGNIN_REQUEST_SUCCESS:
      localStorage.setItem("accessToken", action.payload?.data.access_token);
      localStorage.setItem("botUniqueUserId", Date.now().toString());
      return {
        ...prevState,
        isAuthenticated: true,
        user: { ...action.payload?.data.user },
      };
    case actionTypes.GET_USER_INFO_REQUEST_SUCCESS:
      return {
        ...prevState,
        isAuthenticated: true,
        user: { ...action.payload?.data.user },
      };
    case actionTypes.REGISTER_USER_REQUEST_SUCCESS:
      localStorage.setItem("accessToken", action.payload?.data.access_token);
      return {
        ...prevState,
        isAuthenticated: true,
        user: { ...action.payload?.data.user },
      };
    case actionTypes.LOGOUT:
      localStorage.removeItem("accessToken");
      localStorage.removeItem("botUniqueUserId");
      localStorage.removeItem("model");
      localStorage.removeItem("botAccessTokenEncoded");
      return { ...prevState, isAuthenticated: false, user: { ...{} } };
    case actionTypes.POST_Delete_Profile_SUCCESS:
      localStorage.removeItem("accessToken");
      localStorage.removeItem("model");
      localStorage.removeItem("botUniqueUserId");
      localStorage.removeItem("botAccessTokenEncoded");
      return { ...prevState, isAuthenticated: false, user: { ...{} } };
    default:
      return prevState;
  }
};

export default reducer;

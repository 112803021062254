import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  List,
  styled,
  Typography,
  Card,
  CardContent,
  ListSubheader,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";

// ** Import type
import { FC, ReactNode } from "react";

// ** Import action
import { logout } from "../../store/auth/actions";
import { cleanMessages } from "../../store/messages/actions";
import { RiArrowRightSLine } from "react-icons/ri";
import { updateDataFlow } from "../../store/dataFlow/actions";

// ** define type
interface Item {
  title: string;
  path?: string;
  permission?: string;
  icon: ReactNode;
  option?: ReactNode;
  setting?: ReactNode;
  link?: string;
}

interface Section {
  key: number;
  title?: string;
  item: Item[];
}

interface SideBarSectionProps {
  item: Section;
}

export const BootstrapList = styled(List)(({ theme }) => ({
  "& .Mui-selected": {
    backgroundColor: "transparent !important",
    border: "4.01px solid #A52230",
    borderTopStyle: "none",
    borderRightStyle: "none",
    borderBottomStyle: "none",
    "& .MuiListItemIcon-root": {
      color:
        theme?.palette.mode === "light" ? "#A52230" : "rgba(255,255,255,1)",
    },
    span: {
      color:
        theme?.palette.mode === "light" ? "#A52230" : "rgba(255,255,255,1)",
    },
  },
  "& .MuiListItemIcon-root": {
    color:
      theme?.palette.mode === "light" ? "#000080" : "rgba(255,255,255,0.5)",
    minWidth: "22px",
  },
  "& .MuiSvgIcon-root": {
    width: "15.75px",
    height: "15.71px",
  },
  "& .MuiButtonBase-root": {
    padding: "0px",
    marginTop: "0px",
  },
  "& .MuiButtonBase-root:hover": {
    backgroundColor: "transparent",
    "& .MuiListItemIcon-root": {
      color:
        theme?.palette.mode === "light" ? "#A52230" : "rgba(255,255,255,1)",
    },
    span: {
      color:
        theme?.palette.mode === "light" ? "#A52230" : "rgba(255,255,255,1)",
    },
  },
  "& .MuiListSubheader-root": {
    backgroundColor: "transparent",
    fontFamily: "DM Sans",
    fontWeight: "700",
    fontStyle: "normal",
    fontSize: "15px",
    padding: "0px",
    lineHeight: "1.78",
    color: theme?.palette.mode === "light" ? "#000080" : "rgba(255,255,255,1)",
    marginBottom: "0px",
  },
  span: {
    fontFamily: "DM Sans",
    fontWeight: "700",
    fontStyle: "normal",
    fontSize: "15px",
    lineHeight: 1.78,
    color: theme?.palette.mode === "light" ? "#000080" : "rgba(255,255,255,1)",
  },
}));

const ContentSection: FC<SideBarSectionProps> = ({ item }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.auth);

  const onClickHandler = (path: string | undefined) => {
    if (typeof path === "string") {
      return () => {
        navigate(path);
      };
    } else {
      return () => {
        // alert("here");
        dispatch(cleanMessages());
        dispatch(logout());
      };
    }
  };
  const handleLogout = () => {
    dispatch(cleanMessages());
    dispatch(logout());
  };
  const handleSettings = (path: string) => {
    dispatch(updateDataFlow(path));
  };

  return (
    <>
      <div className="flex flex-col gap-3 px-0 pb-1 text-sm  sm:px-6 sm:pb-2 md:ps-0 ">
        <BootstrapList
          subheader={
            item.title ? (
              <ListSubheader
                sx={{
                  position: "relative",
                }}
              >
                {item.title}
              </ListSubheader>
            ) : null
          }
        >
          <Card className="w-full">
            <CardContent className=" !py-0 !px-1 ">
              {item?.item?.map((_item, idx) => {
                // if (user.role === "user" && !_item.permission) {
                if (
                  _item?.permission !== "pro" ||
                  user.role === _item.permission ||
                  user.role === "admin"
                ) {
                  return (
                    <ListItem
                      className="border-t-[1px]"
                      key={_item.title}
                      disablePadding
                      sx={{
                        marginBottom: "0px",
                        borderTop: idx === 0 ? "1px" : "",
                      }}
                    >
                      <ListItemButton
                        sx={{
                          cursor:
                            _item?.option || _item?.link ? "pointer" : "auto",
                        }}
                        selected={location.pathname === _item.path}
                        onClick={() => {
                          _item?.title === "Logout"
                            ? handleLogout()
                            : onClickHandler(_item.path);
                        }}
                      >
                        <ListItemIcon>{_item.icon}</ListItemIcon>
                        <a
                          href={_item?.link ? _item?.link : "#"}
                          target={_item?.link ? "_blank" : "_self"}
                          rel="noreferrer"
                        >
                          <ListItemText
                            sx={{
                              marginLeft: "5px",
                            }}
                            primary={_item.title}
                          />
                        </a>
                      </ListItemButton>
                      <ListItemIcon>
                        {_item.title === "Email" && (
                          <Typography
                            color="blue-gray"
                            className="font-light !text-xs text-[inherit]"
                          >
                            {user?.email}
                          </Typography>
                        )}
                        {_item?.option ? (
                          <span
                            className="cursor-pointer m-3"
                            onClick={() => {
                              handleSettings(_item.title);
                            }}
                          >
                            <RiArrowRightSLine className="!text-xl" />
                          </span>
                        ) : null}
                        {_item?.setting ? _item?.setting : null}
                      </ListItemIcon>
                    </ListItem>
                  );
                } else {
                  return null;
                }
              })}
            </CardContent>
          </Card>
        </BootstrapList>
      </div>
    </>
  );
};

export default ContentSection;
